import React, { useRef, useEffect } from 'react';

import { Colors } from '../../themes/colors';
import closeIcon from '../../images/close-icon.svg';
import Button from '../../commonComponents/Button';

export default ({
  closePopupClicked,
  btnConfirmClicked,
  adjustedHours,
  adjustedBreak,
  totalDifferenceHours,
}) => {
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopupClicked();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const DetailedDataContainer = (data) => (
    <div
      style={{
        fontSize: 14,
        color: Colors.neutral_600,
        fontFamily: 'Nunito',
        textAlign: 'center',
      }}
    >
      <h3
        style={{
          fontSize: 16,
          color: Colors.neutral_600,
          fontFamily: 'Nunito',
          textAlign: 'center',
          marginBottom: 5,
          marginTop: 14.25,
          lineHeight: '22px',
        }}
      >
        {`${data.title}:`}
      </h3>
      {`${data.value}`}
    </div>
  );

  return (
    <div className="modal" style={{ position: 'fixed' }}>
      <div
        className="modal_content"
        ref={wrapperRef}
        style={{
          position: 'relative',
          width: 'auto',
          padding: '32px 55px 35px',
          top: '-100px',
        }}
      >
        <div style={{ width: 278 }}>
          <img
            src={closeIcon}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              marginTop: 8,
              marginRight: 8,
              top: 0,
              right: 0,
            }}
            onClick={closePopupClicked}
            alt="close_icon"
          />

          <h1
            className="title_font"
            style={{
              color: Colors.primary_500,
              fontSize: 20,
              fontFamily: 'Nunito',
              textAlign: 'center',
              marginTop: 0,
              marginBottom: 8,
              lineHeight: '27px',
            }}
          >
            Confirm Adjustment
          </h1>
          <div
            style={{
              fontSize: 14,
              lineHeight: '19px',
              color: Colors.neutral_600,
              fontFamily: 'Nunito',
              textAlign: 'center',
            }}
          >
            Please confirm the difference in your adjustment request
          </div>
          <DetailedDataContainer title="Adjusted Hours" value={adjustedHours} />
          <DetailedDataContainer title="Adjusted Meal Break" value={adjustedBreak} />
          <div className="line" style={{ width: '100%', margin: '9.25px 0px 9px' }} />
          <div
            style={{
              fontSize: 14,
              color: Colors.neutral_600,
              fontFamily: 'Nunito',
              textAlign: 'center',
            }}
          >
            <h3
              style={{
                fontSize: 16,
                color: Colors.success_500,
                fontFamily: 'Nunito',
                textAlign: 'center',
                marginBottom: 4,
                marginTop: 0,
              }}
            >
              Total Difference:
            </h3>
            {totalDifferenceHours}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '23.25px 0px 0px',
            }}
          >
            <Button
              text="Confirm"
              style={{ width: 193 }}
              onClick={btnConfirmClicked}
            />

            <Button
              text="Go Back"
              style={{
                width: 193,
                marginTop: 19,
                backgroundColor: Colors.secondary_500,
              }}
              onClick={closePopupClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
