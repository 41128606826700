export const Column = ({ children, width, height, gap, style, ...props }) => (
  <div
    {...props}
    style={{
      display: 'flex',
      flexDirection: 'column',
      width,
      height,
      gap,
      ...style,
    }}
  >
    {children}
  </div>
);