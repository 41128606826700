import {
  call, put, take, all, fork,
} from 'redux-saga/effects';
import * as actions from '../actions';
import { apiMethods, apiMethodsConst } from '../services/api';

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload: response });
}

function* callAndDispatchPayload(method, payload) {
  yield call(apiMethods[method], payload);
  yield put({ type: method, payload });
}

function* watchFetchFavoriteProfessionals() {
  const events = actions.createRequestTypes(actions.FETCH_FAVORITE_PROFESSIONALS);
  while (true) {
    const { jobId } = yield take(actions.FETCH_FAVORITE_PROFESSIONALS);
    const params = jobId ? { jobId } : {};
    try {
      yield put({ type: events.REQUEST });
      yield* callAndDispatch(apiMethodsConst.FETCH_FAVORITE_PROFESSIONALS_REQUEST, params);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchRecentProfessionals() {
  const events = actions.createRequestTypes(actions.FETCH_RECENT_PROFESSIONALS);
  while (true) {
    const { filter } = yield take(actions.FETCH_RECENT_PROFESSIONALS);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_RECENT_PROFESSIONALS_REQUEST, { 
        ...(filter !== 'all' && {
          professions: filter,
        }) 
      });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchRemoveFavoriteProfessional() {
  const events = actions.createRequestTypes(actions.REMOVE_FAVORITE_PROFESSIONAL);
  while (true) {
    const { userId } = yield take(actions.REMOVE_FAVORITE_PROFESSIONAL);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.DELETE_FAVORITE_PROFESSIONAL_REQUEST, userId);
      yield* callAndDispatch(apiMethodsConst.FETCH_FAVORITE_PROFESSIONALS_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchPatchFavoriteProfessional() {
  const events = actions.createRequestTypes(actions.ADD_FAVORITE_PROFESSIONAL);
  while (true) {
    const { userId } = yield take(actions.ADD_FAVORITE_PROFESSIONAL);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.ADD_FAVORITE_PROFESSIONAL_REQUEST, userId);
      yield put({ type: events.SUCCESS, payload: userId });
      yield* callAndDispatch(apiMethodsConst.FETCH_FAVORITE_PROFESSIONALS_REQUEST);
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchInviteFavoriteToJob() {
  const events = actions.createRequestTypes(actions.INVITE_FAVORITE_TO_JOB);
  while (true) {
    const payload = yield take(actions.INVITE_FAVORITE_TO_JOB);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.INVITE_FAVORITE_TO_JOB_REQUEST, payload);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchHighlyRatedProfessionals() {
  const events = actions.createRequestTypes(actions.FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION);
  while (true) {
    const { page, limit, filter } = yield take(actions.FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_HIGHLY_RATED_PROFESSIONALS_REQUEST, { 
        page,
        limit,
        ...(filter !== 'all' && {
          professions: filter,
        }) 
      });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

export default function* sagaFavoritesProfessionals() {
  yield all([
    fork(watchFetchFavoriteProfessionals),
    fork(watchRemoveFavoriteProfessional),
    fork(watchPatchFavoriteProfessional),
    fork(watchInviteFavoriteToJob),
    fork(watchFetchRecentProfessionals),
    fork(watchFetchHighlyRatedProfessionals),
  ]);
}
