import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CANCEL_JOB_ACTION, FETCH_ACTION_REQUIRED_JOBS_ACTION, fetchDashboardInfo } from '../../../../../actions';
import { createSuccessSelector } from '../../../../../api/selectors';
import history from '../../../../../history';
import { FilterStatusEnum } from '../../../../../utils/Enum';
import { headerPendingTabTitles } from '../../utils';
import { CustomTable } from '../CustomTable';
import { renderRow } from './renderRow';
import { ExpirationUpdatePopup } from './ExpirationUpdatePopup';

const actions = [FETCH_ACTION_REQUIRED_JOBS_ACTION];
const successActions = [CANCEL_JOB_ACTION];
const successSelector = createSuccessSelector(successActions);

export const PendingTab = () => {
  const dispatch = useDispatch();
  const pendingJobs = useSelector((state) => state.job.actionRequiredJobs);
  const removedSuccessfully = useSelector((state) => successSelector(state));

  const handleOnClick = (item) => {
    const candidateId = item?.multipleCounters?.[0]?.candidate?.id
    const shiftId = item?.id;
    if (candidateId && shiftId) {  
      history.push(`/dashboard/job/${item?.id}/confirm/${candidateId}`);  
    }  
  };

  useEffect(() => {
    if (removedSuccessfully) {
      dispatch(fetchDashboardInfo());
    }
  }, [removedSuccessfully, dispatch]);

  return (
    <>
      <CustomTable
        type={FilterStatusEnum.PENDING}
        emptyMessageType="action needed"
        data={pendingJobs}
        renderRow={(item) => renderRow(item, handleOnClick)}
        headerTitles={headerPendingTabTitles}
        onRowClick={(item) => handleOnClick(item)}
        actions={actions}
      />

      <ExpirationUpdatePopup />
    </>
  );
};
