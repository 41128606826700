export const Box = ({ children, width, height, gap, style, ...props }) => (
  <div
    {...props}
    style={{
      width,
      height,
      gap,
      ...style,
    }}
  >
    {children}
  </div>
);
