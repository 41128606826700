import '../../../themes/global.scss';
import '../../../themes/home.scss';

import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProfessionals } from '../../../actions';
import { UserProfession } from '../../../enums/UserProfession';
import { getShouldAllowOfficeToPostProcedure, getShouldOfficeShowEfda } from '../../../growthbook';
import briefcaseIcon from '../../../images/briefcase.svg';
import calendarIcon from '../../../images/calendar-icon.svg';
import userIcon from '../../../images/user-icon.svg';
import MailIcon from '../../../images/mail-solid.svg';
import { Colors } from '../../../themes/colors';
import { getAllProfessionTypeOptions } from '../../../utils/DentistUtils';
import { EducationUtils } from '../../../utils/EducationUtils';
import Tooltip from '../../Tooltip';
import { workerClassificationTooltipDescription } from '../constants';
import { ProcedureCollapsibleSelection } from '../temp/ProcedureCollapsibleSelection';
import { AssistedHygieneAvailableRow } from './components/Rows/AssistedHygieneAvailableRow';
import { AutoConfirmRow } from './components/Rows/AutoConfirmRow';
import { BreakRow } from './components/Rows/BreakRow';
import { DateRow } from './components/Rows/DateRow/DateRow';
import { HourlyRateRow } from './components/Rows/HourlyRateRow';
import { HoursRow } from './components/Rows/HoursRow';
import { OfficeRow } from './components/Rows/OfficeRow';
import { ProfessionalRow } from './components/Rows/ProfessionalRow';
import { SkillsRow } from './components/Rows/SkillsRow';
import { InviteRow } from '../rebook/components/Rows/InviteRow';

export default ({ job, formValues, setFormValues, showInvite = false, defaultOpenCardIndex = null }) => {
  const user = useSelector((state) => state.user.selectedChildOffice);
  const parentUser = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const {
    lunch,
    professionType,
    skills,
    enableAutoFill,
    enableAutoFillFavorites,
    enableAutoFillHighlyRated,
    rate,
    isLunchPaid,
    offerEndTime,
    procedures,
    offerStartTime,
    isAssistedHygiene,
    professional,
  } = formValues;

  // original profession type code, including sub-profession
  const professionTypeCode = UserProfession.getCode(professionType);

  const allProfessions = useSelector((state) => state.user.allProfessions);
  const showEfda = getShouldOfficeShowEfda();
  const allProfessionOptions = getAllProfessionTypeOptions(allProfessions, { showEfda });

  const userEducation = useSelector((state) => state.user.userEducation);

  const normalizedProfessionCode = UserProfession.getNormalizedCode(professionTypeCode);
  const specialtiesAndProcedures = userEducation?.[normalizedProfessionCode]
    ?.specialtiesAndProcedures;

  const averageRating = useSelector((state) => state.job.averageRating) || {
    average: 49,
    max: 100,
    min: 18,
  };

  const availableProcedures = useMemo(
    () =>
      EducationUtils.getProceduresOfProfession({
        specialtiesAndProcedures,
        profession: UserProfession.getNormalizedCode(professionTypeCode),
        specialties: [skills],
      }),
    [skills, professionTypeCode, specialtiesAndProcedures],
  );

  const [selectedProcedureIndices, setSelectedProcedureIndices] = useState([]);

  const profRate = averageRating[professionTypeCode];

  const [openCardIndex, setOpenCardIndex] = useState(defaultOpenCardIndex);
  const rateRef = useRef(null);

  const handleCardToggle = (index) => {
    setOpenCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    if (defaultOpenCardIndex === 'rate') {
      rateRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [defaultOpenCardIndex]);

  // updates selected procedures when available procedures is available
  // or not empty
  //
  // this, to handle `userEducation` might still load when editing occurs
  useEffect(() => {
    if (
      availableProcedures &&
      availableProcedures.length > 0 &&
      procedures &&
      procedures.length > 0
    ) {
      const defaultProcedureIndices = procedures
        .map((procedure) =>
          availableProcedures.findIndex((currentProcedure) => procedure === currentProcedure),
        )
        .filter((procedureIndex) => procedureIndex > -1);

      setSelectedProcedureIndices(defaultProcedureIndices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableProcedures]);

  useEffect(() => {
    setSelectedProcedureIndices([]);
  }, [professionType, skills]);

  useEffect(() => {
    dispatch(getAllProfessionals());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedProcedureIndices([]);
  }, [professionType, skills]);

  const renderJobField = ({ title, value, icon }) => (
    <div
      className="row_container"
      style={{
        border: `1.5px solid ${Colors.neutral_100}`,
        borderRadius: 8,
        width: '60%',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 32,
          paddingRight: 52,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="icon" src={icon} style={{ width: 24, height: 24, marginRight: 8 }} />
          <p className="date">{title}</p>
        </div>

        <span
          style={{
            fontFamily: 'Nunito',
            color: Colors.neutral_600,
            textTransform: 'capitalize',
          }}
        >
          {value}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          gap: 8,
        }}
      >
        <OfficeRow value={user?.office_name} />

        {showInvite && (
          <>
            {!job && (
              <InviteRow
                isOpen={openCardIndex === 'invite'}
                onToggle={() => handleCardToggle('invite')}
                onConfirm={(value) => {
                  setFormValues((prevState) => ({
                    ...prevState,
                    professional: value,
                  }));
                }}
                defaultProfessionalId={professional?.id}
              />
            )}

            {job &&
              renderJobField({
                title: 'Invite:',
                value: job?.professional?.name,
                icon: MailIcon,
              })}
          </>
        )}

        {parentUser.childOffices.length > 0 &&
          user.isWorkerClassificationEnabled &&
          user.workerClassification &&
          renderJobField({
            title: (
              <span
                style={{
                  marginRight: 16,
                }}
              >
                Worker Type:
                <span
                  style={{
                    marginLeft: '5px',
                    position: 'relative',
                    top: '5px',
                  }}
                >
                  <Tooltip content={workerClassificationTooltipDescription} />
                </span>
              </span>
            ),
            value:
              user.workerClassification.classificationType === 'contractor'
                ? 'Contracted'
                : 'Employed',
            icon: briefcaseIcon,
          })}

        {job &&
          renderJobField({
            title: 'Date:',
            value: moment(job?.localDate).format('ddd, MMM DD, YYYY'),
            icon: calendarIcon,
          })}

        {!job && (
          <DateRow
            isOpen={openCardIndex === 'dates'}
            onToggle={() => handleCardToggle('dates')}
            showInvite={showInvite}
          />
        )}

        {job &&
          renderJobField({
            title: 'Professional:',
            value: !professionType ? 'Profession Type' : professionType,
            icon: userIcon,
          })}

        {!job && (
          <ProfessionalRow
            isOpen={openCardIndex === 'professional'}
            onToggle={() => handleCardToggle('professional')}
            onConfirm={({ type }) => {
              setFormValues((prevState) => ({
                ...prevState,
                professionType: type,
                rate: 0,
              }));
            }}
            defaultSkill={skills}
            professionOptions={allProfessionOptions}
          />
        )}

        <SkillsRow
          isOpen={openCardIndex === 'skills'}
          onToggle={() => handleCardToggle('skills')}
          onConfirm={({ selectedSkill }) => {
            setFormValues((prevState) => ({
              ...prevState,
              skills: selectedSkill,
            }));
          }}
          defaultSkill={skills}
          specialtiesAndProcedures={specialtiesAndProcedures}
        />

        {professionTypeCode === UserProfession.RDH && (
          <AssistedHygieneAvailableRow
            isOpen={openCardIndex === 'isAssistedHygiene'}
            onToggle={() => handleCardToggle('isAssistedHygiene')}
            onConfirm={(value) => {
              setFormValues((prevState) => ({
                ...prevState,
                isAssistedHygiene: value,
              }));
            }}
            defaultValue={isAssistedHygiene}
          />
        )}

        {getShouldAllowOfficeToPostProcedure() && (
          <ProcedureCollapsibleSelection
            useRowStyle
            availableProcedures={availableProcedures}
            selectedProcedureIndices={selectedProcedureIndices}
            expanded={openCardIndex === 'procedures'}
            onPressed={() => handleCardToggle('procedures')}
            onItemPressed={(index) => {
              const indices = (() => {
                const selected = selectedProcedureIndices.includes(index);
                const collection = selectedProcedureIndices;
                const maxSelection = 4;

                if (selected) {
                  return collection.filter((currentIndex) => currentIndex !== index);
                }

                if (!(maxSelection && collection.length >= maxSelection)) {
                  return [...collection, index];
                }

                return collection;
              })();

              setSelectedProcedureIndices(indices);
              setFormValues((prevState) => ({
                ...prevState,
                procedures: indices.map((index) => availableProcedures[index]),
              }));
            }}
          />
        )}

        <HoursRow
          isOpen={openCardIndex === 'hours'}
          onToggle={() => handleCardToggle('hours')}
          defaultArrival={offerStartTime}
          defaultDeparture={offerEndTime}
          onConfirm={(arrival, departure) => {
            setFormValues((prevState) => ({
              ...prevState,
              offerStartTime: arrival,
              offerEndTime: departure,
            }));
            handleCardToggle('break');
          }}
        />

        <BreakRow
          isOpen={openCardIndex === 'break'}
          onToggle={() => handleCardToggle('break')}
          onConfirm={({ time, isPaid }) => {
            setFormValues((prevState) => ({ ...prevState, lunch: time, isLunchPaid: isPaid }));
          }}
          defaultBreak={lunch}
          defaultIsPaid={isLunchPaid}
        />

        <HourlyRateRow
          ref={rateRef}
          isOpen={openCardIndex === 'rate'}
          onToggle={() => handleCardToggle('rate')}
          onConfirm={(offerRate) => {
            setFormValues((prevState) => ({ ...prevState, rate: offerRate }));
          }}
          averagePrice={profRate}
          defaultValue={rate}
        />

        <AutoConfirmRow
          onConfirm={({ isEnabled }) => {
            setFormValues((prevState) => ({
              ...prevState,
              enableAutoFill: isEnabled?.eligibleProfessionals,
              enableAutoFillFavorites: isEnabled?.favorites,
              enableAutoFillHighlyRated: isEnabled?.highlyRated,
            }));
          }}
          defaultValue={{
            eligibleProfessionals: enableAutoFill,
            favorites: enableAutoFillFavorites,
            highlyRated: enableAutoFillHighlyRated,
          }}
          professionType={professionType}
          skills={skills}
        />
      </div>
    </>
  );
};
