import BulbIcon from '../../../../../images/icons/BulbIcon';
import { Colors } from '../../../../../themes/colors';

export const Tips = ({ onClick }) => (
  <button
    type='button'
    style={{
      all: 'unset',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 4,
      height: 30,
      width: 85,
      backgroundColor: Colors.secondary_500,
      borderRadius: 24,
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 10px 1px rgba(0, 0, 0, 0.15)',
      transition: 'transform 0.2s ease-in-out',
    }}
    onClick={onClick}
    onMouseEnter={(e) => {
      e.currentTarget.style.transform = 'scale(1.05)';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.transform = 'scale(1)';
    }}
  >
    <BulbIcon />
    <b
      style={{
        fontFamily: 'Nunito',
        color: Colors.white,
        fontSize: 16,
        marginTop: 2,
      }}
    >
      Tips
    </b>
  </button>
);