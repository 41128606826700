export const Center = ({ children, width, height, gap, style, ...props }) => (
  <div
    {...props}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      width,
      height,
      gap,
      ...style,
    }}
  >
    {children}
  </div>
);
