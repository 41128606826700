import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADJUST_HOURS_ACTION, FETCH_JOB_ACTION, adjustHours, fetchJob } from '../actions';
import { createLoadingSelector } from '../api/selectors';
import Button from '../commonComponents/Button';
import CardSpinner from '../commonComponents/CardSpinner';
import MobileAppModal from '../commonComponents/MobileAppModal';
import ShowStartTime from '../commonComponents/showStartTime';
import { populateTimeDifferenceValue, timeDifferenceCalculator, timeDifferenceFormValueMapping } from '../hooks/timeDifferenceCalculator';
import { Colors } from '../themes/colors';
import '../themes/global.scss';
import '../themes/job_summary.scss';
import AdjustmentItem from './JobSummary/AdjustmentItem';
import ProfileSummary from './JobSummary/ProfileSummary';
import ConfirmAdjustmentPopup from './Request/confirmAdjustmentPopup';
import { showClockInOutFeW2 } from '../growthbook';
import {
  formatBreak,
  isEqualHour,
  pickerResultToTime,
  populateInitialFormValues,
} from './adjustments/utils';
import { trackEvent } from '../api/analytics';

const JobSummaryAdjustment = ({ match, history }) => {
  const dispatch = useDispatch();
  const jobId = match?.params?.jobId;
  const [showConfirmAdjustmentPopupModal, setShowConfirmAdjustmentPopupModal] = useState(false);
  const [isPickerShowStartTime, setIsPickerShowStartTime] = useState(false);
  const [isPickerShowEndTime, setIsPickerShowEndTime] = useState(false);
  const [isPickerShowBreak, setIsPickerShowBreak] = useState(false);
  const [isPickerShowBreakStart, setIsPickerShowBreakStart] = useState(false);
  const [isPickerShowBreakEnd, setIsPickerShowBreakEnd] = useState(false);
  const [originalHours, setOriginalHours] = useState({});
  const [form, setForm] = useState({
    start: null,
    end: null,
    break: null,
    breakStart: null,
    breakEnd: null,
    breakPaid: null,
    message: '',
  });

  const job = useSelector((state) => state.job.job);
  const isNewBreakFlowEnabled = job?.workerClassification === 'employee' && showClockInOutFeW2();
  const isArrivalAdjusted = !isEqualHour(originalHours.start, form.start);
  const isDepartureAdjusted = !isEqualHour(originalHours.end, form.end);
  const isBreakStartAdjusted = !isEqualHour(originalHours.breakStart, form.breakStart);
  const isBreakEndAdjusted = !isEqualHour(originalHours.breakEnd, form.breakEnd);
  const isBreakAdjusted = originalHours.break !== form.break;
  const isAdjusted =
    isArrivalAdjusted ||
    isDepartureAdjusted ||
    isBreakAdjusted;
  const invalidForm =
    !form.start ||
    !form.end ||
    (form.breakStart && !form.breakEnd) ||
    (form.breakEnd && !form.breakStart) ||
    (isAdjusted && !form.message);

  const actions = [FETCH_JOB_ACTION, ADJUST_HOURS_ACTION];
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));

  useEffect(() => {
    if (!job || jobId !== job.id) {
      dispatch(fetchJob({ jobId }));
    }
  }, [jobId]);

  useEffect(() => {
    if (job && job.isFeedbackSubmited) {
      history.replace(`/dashboard/job/${job.id}/details`);
    }

    if (job) {
      const {
        originalStartHour,
        originalEndHour,
        originalBreakTime,
        adjustedStartHour,
        adjustedEndHour,
        adjustedBreakTime,
        breakStart,
        breakEnd,
      } = populateInitialFormValues(job, isNewBreakFlowEnabled);

      setOriginalHours({
        start: originalStartHour,
        end: originalEndHour,
        break: originalBreakTime,
        breakStart,
        breakEnd,
        breakPaid: job?.is_lunch_break_paid,
      });

      setForm({
        start: adjustedStartHour,
        end: adjustedEndHour,
        break: adjustedBreakTime,
        breakStart,
        breakEnd,
        breakPaid: job?.is_lunch_break_paid,
        message: '',
      });
    }
  }, [job]);

  const handleBreakChange = (newFormValue) => {
    const { breakStart, breakEnd } = newFormValue;

    if (breakStart && breakEnd) {
      const breakTime = moment(breakEnd).diff(moment(breakStart), 'minutes');
      setForm({ ...newFormValue, break: String(breakTime) });
    } else {
      setForm({ ...newFormValue, break: null });
    }
  };

  const submitAdjustment = () => {
    const data = {
      break: form.break,
      localDate: job.local_date,
      localStart: moment(form.start).format('hh:mm a').toString(),
      localEnd: moment(form.end).format('hh:mm a').toString(),
      message: form.message,
    };

    const { originalTimeDifference, adjustedTimeDifference } = populateTimeDifferenceValue(
      timeDifferenceFormValueMapping(job, form),
    );

    trackEvent('Adjustment Submitted By Office', {
      shiftId: job?.id,
      originalHoursTotal: originalTimeDifference,
      adjustedHoursTotal: adjustedTimeDifference,
      reason: data?.message,
    });

    dispatch(adjustHours({ data, jobId, navigateToRating: true }));
  };

  if (!job || isLoading) {
    return <CardSpinner />;
  }

  return (
    <>
      <div className="component_container">
        <div style={{ width: '100%' }}>
          <ProfileSummary title="Job Summary" job={job} />

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '-1px 20px 10px 20px',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: Colors.white,
                    paddingBlock: 62,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow:
                      '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <div
                    style={{
                      maxWidth: 658,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p className="global_font bold f-dark" style={{ fontSize: 22 }}>
                      Shift Details
                    </p>

                    <p
                      className="global_font f-16"
                      style={{
                        color: Colors.neutral_500,
                        textAlign: 'center',
                        fontStyle: 'italic',
                      }}
                    >
                      If this shift has changed, edit the adjustments below.
                      <br />
                      If there are not changes to be submitted, click “Skip”
                    </p>

                    <p
                      className="global_font f-dark f-18"
                      style={{ marginTop: 50, marginBottom: 35 }}
                    >
                      <span className="global_font bold">Shift Date:</span> {job.local_date}
                    </p>

                    <div style={{ width: '90%' }}>
                      <AdjustmentItem
                        firstRow
                        label="Arrival:"
                        adjustedLabel="Adjusted Arrival:"
                        defaultValue={moment(originalHours.start).format('hh:mm a')}
                        value={moment(form.start).format('hh:mm a')}
                        isAdjusted={isArrivalAdjusted}
                        onEditClicks={() => setIsPickerShowStartTime(true)}
                      />
                      <AdjustmentItem
                        label="Departure:"
                        adjustedLabel="Adjusted Departure:"
                        defaultValue={moment(originalHours.end).format('hh:mm a')}
                        value={moment(form.end).format('hh:mm a')}
                        isAdjusted={isDepartureAdjusted}
                        onEditClicks={() => setIsPickerShowEndTime(true)}
                      />
                      {isNewBreakFlowEnabled && (
                        <AdjustmentItem
                          label="Meal Break Start:"
                          adjustedLabel="Adjusted Meal Break Start:"
                          defaultValue={
                            !originalHours.breakStart
                              ? '--'
                              : moment(originalHours.breakStart).format('hh:mm a')
                          }
                          value={moment(form.breakStart).format('hh:mm a')}
                          isAdjusted={isBreakStartAdjusted}
                          onEditClicks={() => setIsPickerShowBreakStart(true)}
                        />
                      )}
                      {isNewBreakFlowEnabled && (
                        <AdjustmentItem
                          label="Meal Break End:"
                          adjustedLabel="Adjusted Meal Break End:"
                          defaultValue={
                            !originalHours.breakEnd
                              ? '--'
                              : moment(originalHours.breakEnd).format('hh:mm a')
                          }
                          value={moment(form.breakEnd).format('hh:mm a')}
                          isAdjusted={isBreakEndAdjusted}
                          onEditClicks={() => setIsPickerShowBreakEnd(true)}
                        />
                      )}
                      {isNewBreakFlowEnabled ? (
                        <AdjustmentItem
                          label="Meal Break Time:"
                          adjustedLabel="Adjusted Meal Break Time:"
                          defaultValue={formatBreak(form.break, form.breakPaid)}
                          disabled
                        />
                      ) : (
                        <AdjustmentItem
                          label="Meal Break:"
                          adjustedLabel="Adjusted Meal Break:"
                          defaultValue={formatBreak(originalHours.break, originalHours.breakPaid)}
                          value={formatBreak(form.break, form.breakPaid)}
                          isAdjusted={isBreakAdjusted}
                          onEditClicks={() => setIsPickerShowBreak(true)}
                        />
                      )}
                    </div>

                    {isAdjusted ? (
                      <>
                        <span
                          className="f-18 global_font f-dark bold"
                          style={{ marginTop: '60px', alignSelf: 'flex-start' }}
                        >
                          Please, let us know the reason for this adjustment.
                        </span>

                        <span
                          className="f-16 global_font"
                          style={{ color: Colors.neutral_500, alignSelf: 'flex-start' }}
                        >
                          (Required to complete the adjustment request)
                          <span style={{ color: Colors.error_500, fontWeight: 700 }}>*</span>
                        </span>

                        <textarea
                          className="textarea-style"
                          style={{ width: 468, marginTop: 13 }}
                          placeholder="Reason for adjustment"
                          value={form.message}
                          onChange={(e) => setForm({ ...form, message: e.target.value })}
                        />

                        <span
                          className="f-16 global_font"
                          style={{
                            marginTop: '25px',
                            width: '95%',
                            color: Colors.neutral_500,
                            fontStyle: 'italic',
                            textAlign: 'center',
                          }}
                        >
                          After confirming with the other party, you will be notified of any
                          adjustments.
                        </span>

                        <div style={{ marginTop: '42px' }}>
                          <Button
                            text="Submit & Continue"
                            disabled={invalidForm}
                            onClick={() => setShowConfirmAdjustmentPopupModal(true)}
                          />
                        </div>
                      </>
                    ) : (
                      <div style={{ marginTop: '70px' }}>
                        <Button
                          text="Skip"
                          onClick={() => {
                            const { originalTimeDifference, adjustedTimeDifference } =
                              populateTimeDifferenceValue(
                                timeDifferenceFormValueMapping(job, form),
                              );

                            trackEvent('Job Summary - EOD Adj Screen - Skip Button Clicked', {
                              shiftId: job?.id,
                              originalHoursTotal: originalTimeDifference,
                              adjustedHoursTotal: adjustedTimeDifference,
                            });

                            history.push(`/dashboard/job/${jobId}/feedback-rating`);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPickerShowStartTime && (
        <ShowStartTime
          content="start_time"
          result={(result) => {
            setForm({ ...form, start: pickerResultToTime(job, result?.toLowerCase()) });
          }}
          closeModal={() => setIsPickerShowStartTime(false)}
          height={973}
          defaultStartTime={form.start}
          defaultEndTime={form.end}
          minuteInterval={isNewBreakFlowEnabled ? 1 : 15}
        />
      )}

      {isPickerShowEndTime && (
        <ShowStartTime
          content="end_time"
          result={(result) => {
            setForm({ ...form, end: pickerResultToTime(job, result?.toLowerCase()) });
          }}
          closeModal={() => setIsPickerShowEndTime(false)}
          height={973}
          defaultStartTime={form.start}
          defaultEndTime={form.end}
          minuteInterval={isNewBreakFlowEnabled ? 1 : 15}
        />
      )}

      {isPickerShowBreakStart && (
        <ShowStartTime
          content="start_time"
          result={(result) => {
            handleBreakChange({
              ...form,
              breakStart: pickerResultToTime(job, result?.toLowerCase()),
            });
          }}
          closeModal={() => setIsPickerShowBreakStart(false)}
          height={973}
          defaultStartTime={form.breakStart || form.start}
          defaultEndTime={form.breakEnd || form.end}
          minuteInterval={1}
        />
      )}

      {isPickerShowBreakEnd && (
        <ShowStartTime
          content="end_time"
          result={(result) => {
            handleBreakChange({
              ...form,
              breakEnd: pickerResultToTime(job, result?.toLowerCase()),
            });
          }}
          closeModal={() => setIsPickerShowBreakEnd(false)}
          height={973}
          defaultStartTime={form.breakStart || form.start}
          defaultEndTime={form.breakEnd || form.end}
          minuteInterval={1}
        />
      )}

      <MobileAppModal
        show={isPickerShowBreak}
        content="break"
        result={(event) => {
          setForm({ ...form, break: event.time, breakPaid: event.paid });
        }}
        closeModal={() => setIsPickerShowBreak(false)}
        height={973}
      />

      {showConfirmAdjustmentPopupModal ? (
        <ConfirmAdjustmentPopup
          closePopupClicked={() => setShowConfirmAdjustmentPopupModal(false)}
          btnConfirmClicked={() => submitAdjustment()}
          adjustedHours={`${moment(form.start).format('hh:mm a')} - ${moment(form.end).format(
            'hh:mm a',
          )}`}
          adjustedBreak={`${form.break} minutes(${form.breakPaid ? 'Paid' : 'Unpaid'})`}
          totalDifferenceHours={(() => {
            const { 
              originalStartTime,
              originalEndTime,
              adjustedStartTime,
              adjustedEndTime,
              originalLunchBreakTime,
              adjustedLunchBreakTime,
            } = timeDifferenceFormValueMapping(job, form)

            return timeDifferenceCalculator(
              originalStartTime,
              originalEndTime,
              adjustedStartTime,
              adjustedEndTime,
              originalLunchBreakTime,
              adjustedLunchBreakTime
            )
          })()}
        />
      ) : null}
    </>
  );
};

export default JobSummaryAdjustment;
